.content-container {
    width: auto;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgb(238, 243, 245);
}
.content-content {
    margin-top: 20px;
    margin-bottom: 20px;
    width: 60%;
    height: auto;
}
.top {
    height: 200px;
    background-color: rgb(207, 200, 191);
}
.top h1 {
    text-align: left;
    text-transform: uppercase;
    padding-top: 120px;
    padding-left: 20px;
}

.left-content {
    width: 40%;
    background-color: rgb(255, 255, 255);
    position: relative;
}
.right-content {
    width: 60%;
    background-color: rgb(255, 255, 255);
}
.left-content-border {
    border: 2px solid black;
    height: 94%;
    position: absolute;
    top: 65px;
    left: 27px;
    width: 85%;
    pointer-events: none;
}
.top-top-content {
  display: flex;
}
.bottom-content {
  background-color: rgb(255, 255, 255);
  margin: 30px;
}
.top-content {
  background-color: rgb(255, 255, 255);
}
/* CSS Responsive */
@media (max-width: 768px) {
    .content-container {
      width: auto;
      height: auto;
      display: flex;
      background-color: rgb(238, 243, 245);
    }
    .content-content {
      width: 90%;
      margin: 20px auto;
      display: block;
    }
    .left-content {
      width: 100%;
      background-color: rgb(255, 255, 255);
      position: relative;
    }
    .right-content {
      width: 100%;
      background-color: rgb(255, 255, 255);
    }
    .left-content-border {
      display: none; /* Ẩn border khi ở các kích thước màn hình nhỏ hơn */
    }
  }
  
  @media (min-width: 769px) {
    .content-container {
      width: 100%;
      height: auto;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: rgb(238, 243, 245);
    }
    .content-content {
      margin-top: 20px;
      margin-bottom: 20px;
      width: 60%;
      height: auto;
      display: flex;
    }
    .left-content {
      width: 40%;
      background-color: rgb(255, 255, 255);
      position: relative;
    }
    .right-content {
      width: 60%;
      background-color: rgb(255, 255, 255);
    }
    .left-content-border {
      border: 2px solid black;
      height: 94%;
      position: absolute;
      top: 65px;
      left: 27px;
      width: 85%;
      pointer-events: none;
    }
  }
  