.education {
    margin: 50px;
}
.education-title {
    position: relative;
}
.education-title input {
    background-color: rgb(207, 200, 191);
    width: 85%;
    float: right;
    height: 36px;
    border: none;
    margin-bottom: 10px;
    pointer-events: none;
}
.education-title h1 {
    position: absolute;
    font-size: 25px;
    text-transform: uppercase;
}
.education-text h1 {
    font-size: 23px;
}
.education-text h4 {
    font-size: 20px;
    font-weight: 500;
}
.education-text p {
    font-size: 15px;
    margin: unset;
}

@media (max-width: 768px) {
    .education-title input {
        background-color: rgb(207, 200, 191);
        width: 93%;
        float: right;
        height: 36px;
        border: none;
        margin-bottom: 15px;
        pointer-events: none;
    }
}