.profile {
    margin: 15px;
    margin-bottom: 30px;
    padding-top: 20px;
}
.profile-title {
    display: flex;
    align-items: baseline;
    gap: 10px;
}
.profile-title h1 {
    font-size: 25px;
    margin-bottom: 5px;
    text-transform: uppercase;
}
.profile-text p {
    font-size: 18px;
    margin-right: 20px;
}